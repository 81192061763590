.main-content-container {
  margin: auto;
  max-width: 1440px;
  padding-bottom: 5px;
}

.bgOnHover:hover {
  background-color: #0076bd;
  color: #f7f7f7;
}

.css-mfm0ql>.MuiMenuItem-root {
  word-break: normal;
}

.css-1qodaq9 {
  margin-right: 0px;
}

.defaultFont {
  font-size: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #424242;
}

.downloadOutcomeBtn {
  margin: 0px;
  padding: 0px;

}

.customers {
  font-family: 'Source Sans Pro', sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  background-color: rgb(255, 255, 255);
}

.associateOutcome {
  font-family: 'Source Sans Pro', sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  background-color: rgb(255, 255, 255);
}

.associateOutcome th,
.associateOutcome td {
  font-family: 'Source Sans Pro', sans-serif;
  word-break: break-word;
}

.customers td,
.customers th {
  padding: 1px 20px;
  word-break: break-word;
}

.customers tr {
  border: 1px solid #ddd;
}

.customers th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.borderRed {
  border: solid 1.5px #d9221c;
  border-radius: 5px;
}

.percentageDiv {
  top: 40px;
  position: relative;
  padding: 8px;
  padding-left: 18px;
  padding-right: 20px;
  border: 1.5px solid black;
  font-size: 20px;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
  color: #7a7a7a;
}

.centerNameBg {
  background-color: #ffff;
}

.helperTextTwo {
  padding-bottom: 56px;
}

.iconShare {
  position: absolute;
  right: 20px;
}

.submitting {
  margin-top: 30px;
  margin-left: 120px;
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 40px;
  font-weight: 900;
}

.tableRow {
  font-size: 16px;
}

.tableColumn {
  font-size: 16px;
}

.fullWidth {
  height: 100vh;
  background-color: #f5f5f5;
  overflow-y: scroll;
}

.headerText {
  background-color: #212121;
}

.centerNumberCard {
  background-color: #f3f3f3;
}

.cardOutlineBorder {
  border-bottom: solid 2px #d1d1d1;
}

.errorBodyContent {
  color: #bd4f00;
  background-color: #fcf8f3;
  border: solid 1px #bd4f00;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  height: auto;
  margin-right: 2px;
  margin-top: 20px;
}

.startButton {
  text-transform: none;
}

.centerNameContent {
  background-color: #f7f7f7;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.helperTextOne {
  padding-bottom: 28px;
  padding-top: 56px;
}

.subFieldCard {
  height: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  padding-top: 10px;
  padding-bottom: 10px;
  width: auto;
}

.buttonNext {
  margin-top: 25px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.cardHeader {
  display: grid;
  grid-template-columns: 2fr 1fr 0.5fr;
  background-color: #f7f7f7;
  padding: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 0.5fr;
  gap: 50px;
  width: 100%;
}

.css-1j44bw4-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px;
}

.title {
  font-size: 1.5rem;
}

.align-right {
  display: flex;
  justify-content: right;
}

.align-right-reviewed {
  display: flex;
  justify-content: right;
  align-items: flex-start;
}

.align-left {
  display: flex;
  justify-content: left;
}

.qualification-list {
  display: flex;
  justify-content: space-between;
}

.uploadScanBg {
  background-color: #fff;
}

.viewEvidence {
  margin-bottom: 30px;
}

.col1Data {
  padding-left: 1rem;
}

.evidenceFileStyle {
  font-size: 16px;
  columns: #424242;
}

.uploading {
  color: #0274b6;
  font-size: 14px;
}

.checking_for_viruses {
  color: #767676;
  font-size: 14px;
}

.uploadScanBgContainer {
  padding: 0px;
}

.evidenceDetailContainer {
  background-color: #f5f5f5 !important;
  padding: 0px;
}

.uploadAddDetContainer {
  background-color: #f5f5f5 !important;
  padding: 0px;
}

.uploaded {
  color: #2b8000;
  font-size: 14px;
}

.error {
  color: #d9221c;
  font-size: 14px;
}

.uploadDetails {
  margin-top: 5px;
}

.uploadDetails .has-border {
  border-radius: 6px;
  border: 2px solid #d9d9d9;
}

.uploadedEvidenceDiv {
  padding: 1.25rem;
}

div.uploadEvidenceDetails {
  border-top: 2px solid #d9d9d9;
}

div.uploadEvidenceDetails:first-of-type {
  border-top: none;
}

.pageNotFound-container .errorPage,
.unauthorizedLogin-container .errorPage {
  margin: 10% 0;
}

.errorCommon-container .icon {
  width: 68px;
  height: 68px;
  fill: #9e9e9e;
  display: block;
  margin: auto;
  margin-bottom: 9px;
}

.errorCommon-container .heading {
  margin-bottom: 21px;
  text-align: center;
}

.errorCommon-container .paragraph {
  max-width: 460px;
  text-align: center;
  padding-left: 31px;
  padding-right: 31px;
  margin: 0 auto 24px auto;
}

.errorCommon-container .paragraphMinistries {
  max-width: 660px;
  text-align: center;
  padding-left: 31px;
  padding-right: 31px;
  margin: 0 auto 24px auto;
}

.errorCommon-container .button {
  display: block;
  margin: auto;
  text-decoration: none;
  text-transform: none;
}

.box {
  border-radius: 6px;
  color: #4a4a4a;
  padding: 1.25rem;
  background-color: #fff;
}

.has-border {
  border: 1px solid #d9d9d9;
}

.alignCenter {
  align-self: center;
}

.text-alignment {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 600;
}

.text-line-height {
  line-height: 2.2em;
}

.bulkCandfileNameStyle {
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: 5px;
  font-size: 16px;
}

.rejected-background-color {
  display: flex;
  flex-basis: 300px;
  padding: 20px 0;
  background-color: #fadedd;
  margin-top: 10px;
}

.uploadScanBg .box {
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
}

.uploadScanBarInfo {
  display: flex;
  justify-content: space-between;
}

.has-text-danger {
  color: red;
}

[id="upload"]::-webkit-file-upload-button {
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 12px 20px;
  transition: all 1s ease;
  border: none;
}

.middleAlign {
  align-self: center;
}

.file-input {
  opacity: 0;
  outline: 0;
  position: absolute;
}

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.file-cta,
.file-name {
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
}

.file-cta {
  background-color: #f5f5f5;
  color: #4a4a4a;
}

.iconStyleDownloadOpen svg {
  margin-right: 5px;
  vertical-align: bottom;
}

.iconStyleDownloadOpen {
  text-decoration: none;
  cursor: pointer;
  color: #0076BD
}

.iconStyleDownloadOpen:hover,
.iconStyleDownloadOpen:active {
  text-decoration: underline;

}

.childCsvDownloadLink {
  text-decoration: none;
}

.parentCenterName {
  margin-left: 10px;
  background-color: #444444;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.chip-success {
  background-color: #2b8000;
}

.chip-success>span>p {
  color: #f3f3f3;
}

.chip-error {
  background-color: #d9221c;
}

.chip-error>span>p {
  color: #f3f3f3;
}

.chip-updated {
  background-color: #0a0a0a;
}

.chip-updated>span>p {
  color: #f3f3f3;
}

.section-flex {
  display: flex;
  flex-direction: row;
}

.section-flex-item {
  align-self: center;
  max-width: 100%;
}

ul[role="menu"] button[data-testid*="signout"],
ul[role="menu"] li.goToMyCambridgeLink+div {
  display: none;
}

.delete-file-icon {
  margin-left: 6px;
  height: 22px;
  font-weight: normal;
}

.delete-file-icon span {
  margin-right: 1px;
}

.vertical-scroll {
  max-height: 200px;
  overflow: auto;
  padding: 2px;
}

.center-selection-style {
  vertical-align: super;
  padding-left: 5px;
  cursor: pointer;
}

.center-selection-style:hover {
  vertical-align: super;
  padding-left: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.evidencetextfieldStyle1 {
  padding-right: 1%;
}

.evidencetextfieldStyle2 {
  padding-left: 1%;
}

.evidencetextfieldStyle1label {
  white-space: normal;
}

.evidencetextfieldStyle1label,
.evidencetextfieldStyle2label {
  white-space: normal;
}

.fileUploadPanel {
  margin: 20px 0;
}

.candidate-summary-listing {
  display: block;
  border-top: 1px solid #ccc;
  background-color: white;
  padding: 2px;
}

.notification.is-danger.is-light {
  background-color: #feecf0;
  color: #d9221c;
  min-width: 100%;
  border: 1px solid #d9221c;
  border-radius: 4px;
}

.columns:not(.is-desktop) {
  display: flex;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.outcome-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 60px 20px 40px;
}

.outcome-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px 20px 40px;
  max-width: 100%;
}

#outcome-btn-container-first-child {
  max-width: 150px;
  color: #0076bd;
}

#outcome-btn-container-second-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 60px 0px 0px;
}

.outcome-list-container {
  display: flex;
  flex-direction: column;
  margin: 5px 55px 20px 35px;
  max-width: 100%;
  border: 1px solid #ccc;
}

.outcome-list-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #e9e9e9;
  padding: 1rem;
}

.outcome-list-table-body {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-self: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
}

.outcome-list-table-body-item {
  display: flex;
  text-align: left;
  align-items: center;
}

.text-bold {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  word-wrap: break-word;
}

.text-not-bold {
  font-weight: 100;
  font-size: 13px;
  text-align: left;
}

.tag-error {
  background-color: #d9221c;
  color: #fff;
  border-radius: 9999px;
  padding: 2px 5px;
  font-size: small;
  font-weight: 100;
  height: auto;
  margin-top: 10px;
}

.tag-success {
  background-color: #2b8000;
  color: #fff;
  border-radius: 9999px;
  padding: 2px 5px;
  font-size: small;
  font-weight: 100;
  height: auto;
  margin-top: 10px;
}

.outcome-table th {
  vertical-align: top;
  border-bottom: 1px solid #e9e9e9;
}

.outcome-table td {
  vertical-align: top;
  border-bottom: 1px solid #e9e9e9;
}

.outcome-table tr:last-child td {
  border-bottom: none;
}

.table-header-row {
  background-color: "#FAFAFA";
  vertical-align: "top";
  height: "30px";
  border-left: "none";
  border-right: "none";
  border-bottom: "2px solid #E9E9E9";
}

.rejected-top-border {
  display: flex;
  flex-direction: column;
  border-top: 4px solid #d9221c;
}

.approved-top-border {
  display: flex;
  flex-direction: column;
  border-top: 4px solid #2b8000;
}

.nutral-top-border {
  display: flex;
  flex-direction: column;
  border-top: 4px solid #d1d1d1;
}

.flex-two-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.box-with-data {
  border: 1px solid #ccc;
  padding: 8px;
  background-color: rgba(255, 205, 0, 0.4);
}

.box-without-data {
  border: 1px solid #ccc;
  padding: 8px;
}

input[type="file"] {
  font-family: 'Source Sans Pro', sans-serif;
  border: solid 1px #E0E0E0;
  border-radius: 4px;
  width: 100%;
}

input[type="file"]:hover {
  font-family: 'Source Sans Pro', sans-serif
}

.review-child-application {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 0px 0px;
}

.notification-is-nutral {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  min-width: 100%;
  border: 1px solid #acacac;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
}

.examseries-box {
  display: flex;
  flex-direction: column;
  background-color: rgb(240, 240, 240);
  width: 100%;
  border: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.notification-is-nutral-items {
  margin: 0.5rem;
}

.review-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 1.5rem;
}

.child-status-saved {
  color: #444444;
  background-color: #F7F7F7;
  border-color: transparent;
  font-weight: 600;
}

.child-status-open {
  color: #946c00;
  background-color: #F7F7F7;
  font-weight: 600;
}

.child-status-rejected {
  color: #cc0f35;
  background-color: #F7F7F7;
  font-weight: 600;
}

.child-status-success {
  color: #257953;
  background-color: #F7F7F7;
  font-weight: 600;
}

.landing-notification-box {
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  border-width: 1px;
  white-space: nowrap;
  justify-content: center;
  text-align: center;
}


@media only screen and (min-width: 0px) and (max-width: 769px) {
  .main-content-container {
    margin: auto;
    max-width: 769px;
  }

  .helperTextOne {
    padding-top: 20px;
  }

  .css-1flrw15-MuiCardContent-root:last-child {
    padding: 10px 0px 10px 15px;
    line-height: 32px;
  }

  .laptopIcon {
    font-size: 75px;
  }

  .helperTextTwo {
    padding-bottom: 30px;
  }

  .userName {
    font-size: 24px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .main-content-container {
    margin: auto;
    max-width: 1023px;
  }

  .cardHeader {
    display: grid;
    grid-template-columns: 2fr 0.5fr 1fr;
    background-color: #f7f7f7;
    padding: 10px;
  }

  .helperTextTwo {
    padding-bottom: 30px;
  }

  .userName {
    font-size: 24px;
    margin-top: 18px;
  }

  .helperTextOne {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1429px) {
  .main-content-container {
    margin: auto;
    max-width: 1429px;
  }

  .cardHeader {
    display: grid;
    grid-template-columns: 2fr 0.5fr 1fr;
    background-color: #f7f7f7;
    padding: 10px;
  }

  .candidateNumberSubText {
    padding-bottom: 10px;
  }

  .dropDownWidth {
    width: 220px;
  }

  .helperTextTwo {
    padding-bottom: 30px;
  }

  .userName {
    font-size: 25px;
  }

  .helperTextOne {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .centerNameContent {
    padding: 10px;
  }

  .startButton {
    font-size: 14px;
    padding: 8px;
  }

  .tag-error {
    font-size: 8px;
    width: 6rem;
  }

  .tag-success {
    font-size: 8px;
    width: 3.3rem;
  }
}

@media only screen and (min-width: 1440px) {
  .main-content-container {
    margin: auto;
    max-width: 1440px;
  }

  .dropDownWidth {
    width: 328px;
  }

  .candidateNumber {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .candidateNumberSubText {
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1280px) {
  .childSubmitStyle {
    font-size: 11px;
    line-height: 15px;
  }
}